<template lang="pug">
.row(ref="block")
  .col-12(v-if="hasResult")
    om-heading.mb-6.font-size-3(h3) {{ $t('customTheme.chooser.upcomingSeasons') }}
  .col-4.upcoming-season(
    v-for="season in seasons"
    :key="`upcoming-${season.slug}`"
    v-if="getFirstTemplateWithUseCase(season)"
  )
    TemplateCard(
      @observable="addObservable($event.$el)"
      :dimensions="boxDimensions"
      @inited="updateDimensions"
      :template="getFirstTemplateWithUseCase(season)"
      @click="navigateWithFilter(season.slug)"
      heap-event="new-selector-campaign-upcoming-seasons"
      :numberOfTemplates="getTemplates(season).length"
      :hideTitleSeparator="true"
    )
      template(#subtitle)
        om-body-text(bt700lg) {{ season.name }}
</template>

<script>
  import { mapState } from 'vuex';
  import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import childRouteMixin from '../../mixins/childRoute';
  import TemplateCard from '../TemplateCard.vue';

  export default {
    name: 'UpcomingSeasons',
    components: { TemplateCard },
    mixins: [homepageBlockMixin, childRouteMixin, ssrMixin, observableCollectionMixin],
    data: () => ({ ssrBoxSelector: '.upcoming-season .template-thumbnail-box' }),
    computed: {
      ...mapState(['accountType']),
      seasons() {
        return (this.themes?.seasons ?? []).slice(0, 3);
      },
      hasResult() {
        return (
          this.$apollo.queries.templates.loading ||
          this.seasons.find((season) => this.getFirstTemplateWithUseCase(season))
        );
      },
    },
    apollo: {
      templates: {
        query: GET_CHOOSER_FILTERED_TEMPLATES,
        variables() {
          const filter = {
            ...this.filter,
            search: this.search,
            category: this.seasons.map(({ slug }) => slug),
          };
          return { filter, type: this.accountType };
        },
        result() {
          this.$emit('loaded');
        },
      },
    },
    mounted() {
      this.$refs.block.__comp__ = this;
      this.addObservable(this.$refs.block);
    },
    beforeDestroy() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
    },
    methods: {
      onIntersecting() {
        this.updateDimensions();
      },
      onNotIntersecting() {},
      getTemplates(season) {
        const category = season?.slug;
        if (!category) return false;
        return this.templates?.byCategory?.[category] || [];
      },
      getFirstTemplate(season) {
        return this.getTemplates(season)?.[0] ?? false;
      },
      getFirstTemplateWithUseCase(season) {
        return this.getTemplates(season).find(({ useCase }) => !!useCase) ?? false;
      },
      getUseCase(season) {
        const template = this.getFirstTemplateWithUseCase(season);
        if (!template) return false;
        const useCase = this.useCaseMap[template.useCase];
        return useCase || false;
      },
      navigateWithFilter(slug) {
        this.$emit('track', {
          component: 'Upcoming seasons',
          setting: 'theme_open',
          options: this.$t(`templateFilter.categories.${slug}`, 'en'),
        });
        this.$emit('navigateWithFilter', { name: 'seasonal-templates', params: { slug } });
      },
      getPossibleTemplates() {
        return this.seasons?.map?.((season) => this.getTemplates(season))?.flat?.() ?? [];
      },
    },
  };
</script>
